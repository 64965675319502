import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCvQIy1H5DFGtl2CUOSNDfS0OPTW7H0QCM",
  authDomain: "jyvapi.firebaseapp.com",
  projectId: "jyvapi",
  storageBucket: "jyvapi.appspot.com",
  messagingSenderId: "129871227333",
  appId: "1:129871227333:web:0474941eac2848c5bfc3ac",
  measurementId: "G-4CXC6VY0T9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const Auth = getAuth(app);
export const storage = getStorage(app);


// const analytics = getAnalytics(app);

