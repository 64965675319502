/* global ACE */
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { navigate } from "gatsby";
import * as API from "../../utils/api";
import { Auth } from "../../firebase";
import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";

// import { backgroundSessionHasExpired, closedTabSessionHasExpired } from '../../utils';

const defaultState = {
  needsPasswordChange: false,
  isLoggedIn: false,
  authLoading: true,
  loginError: "",
  userId: null,
  changePwRes: {},
  changePwErr: null,
  completeNewPwErr: null,
  featureFlags: [],
  logoutErr: "",
  logoutRes: {},
  toastOpen: false,
  toastTransition: undefined,
  toastMessage: "",
};

const auth = {
  name: "auth",

  state: defaultState,

  reducers: {
    changePasswordSuccess: (state, changePwRes, isSelf) => ({
      ...state,
      changePwRes,
      isLoggedIn: isSelf ? false : true,
      changePwErr: null,
      userId: isSelf ? null : state.userId,
    }),
    changePasswordFailure: (state, changePwErr) => ({
      ...state,
      changePwErr,
    }),
    completeNewPasswordSuccess: (state, completeNewPwRes) => ({
      ...state,
      isLoggedIn: true,
      needsPasswordChange: false,
      userId: completeNewPwRes.username,
    }),
    completeNewPasswordFailure: (state, completeNewPwErr) => ({
      ...state,
      completeNewPwErr,
    }),
    setFeatureFlags: (state, featureFlags) => ({
      ...state,
      featureFlags,
    }),
    loginSuccess: (state, userId) => ({
      ...state,
      userId,
      authLoading: false,
      isLoggedIn: true,
      loginError: "",
    }),
    signUpSuccess: (state) => ({
      ...state,
      authLoading: false,
      signUpError: "",
    }),
    signUpFailure: (state, signUpError) => ({
      ...state,
      authLoading: false,
      isLoggedIn: false,
      signUpError,
    }),
    loginFailure: (state, loginError) => ({
      ...state,
      authLoading: false,
      loginError,
    }),
    logOutSuccess: () => ({
      ...defaultState,
      authLoading: false,
    }),
    logOutFailure: (state, logoutErr) => ({
      ...state,
      logoutErr,
      authLoading: false,
    }),
    openToast: (state, { toastMessage, claimId }) => ({
      ...state,
      newClaimId: claimId,
      toastMessage,
      toastOpen: true,
    }),
    resetToast: (state) => ({
      ...state,
      toastOpen: false,
      toastMessage: "",
      newClaimId: null,
    }),
    setToastTransition: (state, transition) => ({
      ...state,
      toastTransition: transition,
    }),
  },

  effects: (dispatch) => ({
    // async changePassword(payload) {
    //   const { currentPassword, newPassword, isSelf } = payload;
    //   try {
    //     // TODO: update to match firebase Auth
    //     const user = await Auth.currentAuthenticatedUser();
    //     const data = await Auth.changePassword(
    //       user,
    //       currentPassword,
    //       newPassword
    //     );

    //     if (data) {
    //       this.changePasswordSuccess(data, isSelf);
    //       if (isSelf) {
    //         this.logOut();
    //       }
    //       this.openToast({ toastMessage: "Password has been reset." });
    //       return;
    //     }
    //   } catch (err) {
    //     this.changePasswordFailure(err.message);
    //     return;
    //   }
    // },

    // async completeNewPassword(payload) {
    //   const { user, newPassword } = payload;
    //   try {
    //     // TODO: update to match firebase Auth
    //     const data = await Auth.completeNewPassword(user, newPassword);
    //     if (data) {
    //       this.completeNewPasswordSuccess(data, true);
    //       const userPayload = {
    //         sign_on_date: new Date().toISOString(),
    //         user_id: data.username,
    //       };
    //       dispatch.user.signOnUser(userPayload);
    //       dispatch.user.getUser(data.username);
    //       return;
    //     }
    //   } catch (err) {
    //     this.completeNewPasswordFailure(err.message);
    //   }
    // },

    async login(payload) {
      const { email, pw } = payload;
      try {
        const userCredentials = await signInWithEmailAndPassword(
          Auth,
          email,
          pw
        );

        const userId = userCredentials.user.uid;
        const userResponse = await dispatch.user.getUser(userId);

        if (userResponse.type === "GET_USER_FAILURE") {
          throw {
            message: "There is a problem with your account..",
          };
        }

        this.loginSuccess(userId);
        return userId;
      } catch (err) {
        try {
          this.loginFailure(err.message);
          await signOut(Auth);
          this.logOutSuccess();
        } catch (e) {
          this.logOutFailure(err.message);
        }
      }
    },

    async logOut() {
      try {
        await signOut(Auth);
        this.logOutSuccess();
      } catch (err) {
        this.logOutFailure(err.message);
      }
    },

    async signUp(payload) {
      const { email, pw, userData } = payload;
      try {
        const userCredentials = await createUserWithEmailAndPassword(
          Auth,
          email,
          pw
        );
        let updateData = { ...userData };
        updateData.user_id = userCredentials.user.uid;

        await API.initializeUser(updateData);
        this.signUpSuccess();
        await dispatch.auth.login({ email: email, pw: pw });
        return userCredentials.user.uid;
      } catch (err) {
        this.signUpFailure(err.message);
      }
    },

    // // async verifyLogin() {
    // //   try {
    //     if (backgroundSessionHasExpired() || closedTabSessionHasExpired()) {
    //       throw new Error("Your session expired.");
    //     }
    //     const { username } = await Auth.currentAuthenticatedUser();

    //     this.loginSuccess(username);
    //     ACE.getInfoThrottled();

    //     const userResponse = await dispatch.user.getUser(username);

    //     if (userResponse.type === "GET_USER_FAILURE") {
    //       throw {
    //         message:
    //           "Your account has been deactivated.",
    //       };
    //     }
    //   } catch (err) {
    //     this.loginFailure(err.message);
    //     Auth.signOut();
    //   }
    // },
  }),

  selectors: () => ({}),
};

export default auth;
