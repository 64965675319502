import * as API from "../../utils/api";

const defaultState = {
  user: null,
  userErr: "",
  userResetSuccess: {},
  userResetErr: "",
  deleteUserErr: "",
  deleteUserSuccess: {},
  createdUserErr: "",
  createdUser: {},
  pastEvents: [],
  pastEventsErr: "",
  upcomingEvents: [],
  upcomingEventsErr: "",
};

const user = {
  name: "user",

  state: defaultState,

  reducers: {
    getUserSuccess: (state, { user }) => ({
      ...state,
      user,
    }),
    getUserError: (state, userErr) => ({
      ...state,
      userErr,
    }),
    userResetPWSuccess: (state, userResetSuccess) => ({
      ...state,
      userResetSuccess,
    }),
    userResetPWError: (state, userResetErr) => ({
      ...state,
      userResetErr,
    }),
    getUpcomingEventsSuccess: (state, upcomingEvents) => ({
      ...state,
      upcomingEvents: upcomingEvents,
    }),
    getUpcomingEventsError: (state, upcomingEventsErr) => ({
      ...state,
      upcomingEventsErr,
    }),
    getPastEventsSuccess: (state, pastEvents) => ({
      ...state,
      pastEvents: pastEvents,
    }),
    getPastEventsError: (state, pastEventsErr) => ({
      ...state,
      pastEventsErr,
    }),

    // getCrewMembersSuccess: (state, crewMembers) => ({
    //   ...state,
    //   crewMembers,
    // }),
    // getCrewMembersError: (state, usersErr) => ({
    //   ...state,
    //   usersErr,
    // }),
  }, // end reducers

  effects: (dispatch) => ({
    async getUser(id, rootState) {
      try {
        //   TODO: build out featureFlags
        // const { featureFlags } = rootState.auth;

        const res = await API.getUser(id);
        this.getUserSuccess({ user: res.data.data });
        return res.data.data;

        // TODO: uncomment when featureFlags is developed
        // if (res.data.feature_flags) {
        //   await dispatch.auth.setFeatureFlags([...featureFlags, ...res.data.feature_flags]);
        // }
      } catch (err) {
        this.getUserError(err);
      }
    },

    async userResetPW(id) {
      try {
        const res = await API.resetUserPassword(id);
        this.userResetPWSuccess(res.data);
        dispatch.auth.openToast({ toastMessage: "Link sent." });
      } catch (err) {
        this.userResetPWError(err);
      }
    },
    // TODO: uncomment when crewMembers are created
    // async getCrewMembers() {
    //   try {
    //     const res = await API.getCrewMembers();
    //     this.getCrewMembersSuccess(res.data);
    //   } catch (err) {
    //     this.getCrewMembersSuccess(err);
    //   }
    // },
    // TODO: decide if this should be for account updates or profile updates or both
    async updateUser({ data }, rootState) {
      const { user_id } = rootState.user?.user;
      try {
        const res = await API.updateUser(data);
        this.updateUserSuccess(res.data);

        await dispatch.user.getUser(user_id);

        dispatch.auth.openToast({ toastMessage: "Changes saved to account." });
      } catch (err) {
        this.updateUnionUserFailure(err);
      }
    },
    async getUpcomingEvents(payload, rootState) {
      const user_id = rootState?.auth?.userId;
      try {
        const res = await API.getUpcomingEvents(user_id);
        this.getUpcomingEventsSuccess(res.data.data);
        return res.data.data;
      } catch (err) {
        this.getUpcomingEventsError(err);
      }
    },
    async getPastEvents(payload, rootState) {
      const user_id = rootState?.auth.userId;
      try {
        const res = await API.getPastEvents(user_id);
        this.getPastEventsSuccess(res.data.data);
        return res.data.data;
      } catch (err) {
        this.getPastEventsError(err);
      }
    },
    async handleAttendanceUpdate(payload, rootState) {
      try {
        await API.updateAttendance(payload);
        await dispatch.user.getUpcomingEvents();
      } catch (e) {
        this.getUpcomingEventsError(e);
      }
    },
  }), // end effects
  selectors: (slice) => ({
    myUpcomingEvents() {
      return slice((select) => select.user.upcomingEvents);
    },
    myPastEvents() {
      return slice((select) => select.user.pastEvents);
    },
    userId() {
      return slice((select) => select.user.user?.user_id);
    },
  }),
};

export default user;
