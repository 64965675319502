import * as API from "../../utils/api";

const defaultState = {
  event: {},
  content: [],
  contentSuccess: "",
  contentErr: "",
  eventSuccess: "",
  eventErr: "",
};

const eventDetails = {
  name: "eventDetails",

  state: defaultState,

  reducers: {
    getEventSuccess: (state, eventSuccess) => ({
      ...state,
      eventSuccess,
    }),
    getEventError: (state, eventErr) => ({
      ...state,
      eventErr,
    }),
    getContentSuccess: (state, contentSuccess) => ({
      ...state,
      contentSuccess,
    }),
    getContentError: (state, contentErr) => ({
      ...state,
      contentErr,
    }),
  }, // end reducers

  effects: (dispatch) => ({
    async getEventDetails(id, rootState) {
      const { user_id } = rootState.user.user;
      try {
        const res = await API.getEventDetails(id, user_id);
        this.getEventsSuccess({ events: res.data });
      } catch (err) {
        this.getEventsError(err);
      }
    },
  }), // end effects
  selectors: (slice) => ({
    eventDetails() {
      return slice((select) => select.eventDetails.event);
    },
  }),
};

export default eventDetails;
