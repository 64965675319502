// import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from "axios";

import { serialize } from "../components/inputs/rich-text/utils";
import { store } from "../state/create-store";

// async function authToken() {
//   let token = null;
//   try {
//     const {
//       signInUserSession: {
//         accessToken: { jwtToken },
//       },
//     } = await Auth.currentAuthenticatedUser();

//     token = jwtToken;
//   } catch (e) {
//     store.dispatch.auth.logOut();
//   }
//   return token;
// }

export async function authenticatedRequest(
  endpoint: string,
  config: AxiosRequestConfig = {}
) {
  //   let token = await authToken();
  return axios(process.env.GATSBY_API_BASE + endpoint, {
    ...config,
  });
}

// TODO: turn these into selectors on the user redux model
// export function getReleasedContent(id) {
//   return authenticatedRequest("/released-content", {
//     params: {
//       id: id,
//     },
//   });
// }

// export function getScheduledContent(id) {
//   return authenticatedRequest("/scheduled-content", {
//     params: {
//       id: id,
//     },
//   });
// }

export function getUsersSubscriptions(id) {
  return authenticatedRequest(`/user-subscriptions/${id}`);
}

// fetches the user information for the profile being viewed
export function getUserProfile(id) {
  return authenticatedRequest(`/profile/user/${id}`);
}

// fetches the user information for the logged in user
export function getUser(id) {
  return authenticatedRequest(`/user/${id}`);
}

// updates current logged in user
export function updateUser(payload) {
  return authenticatedRequest("/user", {
    method: "POST",
    data: payload,
  });
}

// updates current logged in user
export function updateContent(payload) {
  return authenticatedRequest("/update-content", {
    method: "PUT",
    data: payload,
  });
}

// fetches the events for the logged in user
export function getFollowingEvents(id) {
  return authenticatedRequest(`/user/following-events/${id}`);
}

// fetches the events for the logged in user
export function getUpcomingEvents(id) {
  return authenticatedRequest(`/user/upcoming-events/${id}`);
}

export function getPastEvents(id) {
  return authenticatedRequest(`/user/past-events/${id}`);
}


export function getDiscover(id) {
  return authenticatedRequest(`/my-discover/${id}`);
}

// fetches the subscriptions for the logged in user
export function getUserSubscriptions(id) {
  return authenticatedRequest(`/user/subscriptions/${id}`);
}

// TODO:may not need this function
// fetches the content for the logged in user
export function getContent(id) {
  return authenticatedRequest(`/my-feed/${id}`);
}

// fetches the events for the profile a user is viewing
export function getProfileEvents(id, userId) {
  return authenticatedRequest(`/profile/events/${id}/${userId}`);
}

// fetches the content for the profile a user is viewing
export function getProfileContent(id, userId) {
  return authenticatedRequest(`/profile/content/${id}/${userId}`);
}


export function initializeUser(payload) {
  return authenticatedRequest("/user", {
    method: "POST",
    data: payload,
  });
}

export function subscribeUser(payload) {
  return authenticatedRequest("/subscribe", {
    method: "POST",
    data: payload,
  });
}

export function unsubscribeUser(payload) {
  return authenticatedRequest("/unsubscribe", {
    method: "POST",
    data: payload,
  });
}

export function createContent(payload) {
  return authenticatedRequest("/create-content", {
    method: "POST",
    data: payload,
  });
}

// TODO: createEvent

export function deleteMessage(payload) {
  return authenticatedRequest("/message", {
    method: "DELETE",
    data: payload,
  });
}

// fetches the event details for specific event
// TODO: define this function on api
export function getEventDetails(id, userId) {
  return authenticatedRequest(`/user/event-details/${id}/${userId}`);
}

export function setDownloadUrl(payload) {
  return authenticatedRequest("/set-download-urls", {
    method: "POST",
    data: payload,
  });
}

export function updateAttendance(payload) {
  return authenticatedRequest("/update-attendance", {
    method: "POST",
    data: payload,
  });
}

export function deleteContent(payload){
  return authenticatedRequest("/delete-content", {
    method: "DELETE",
    data: payload,
  });
}
