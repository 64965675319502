import * as API from "../../utils/api";

const defaultState = {
  user: {},
  userErr: "",
  events: [],
  content: [],
  contentSuccess: "",
  contentErr: "",
  eventsSuccess: "",
  eventsErr: "",
  subscribeSuccess: "",
  subscribeErr: "",
};

const profile = {
  name: "profile",

  state: defaultState,

  reducers: {
    getUserSuccess: (state, { user }) => ({
      ...state,
      user,
    }),
    getUserError: (state, userErr) => ({
      ...state,
      userErr,
    }),
    getEventsSuccess: (state, eventsSuccess) => ({
      ...state,
      eventsSuccess,
    }),
    getEventsError: (state, eventsErr) => ({
      ...state,
      eventsErr,
    }),
    getContentSuccess: (state, contentSuccess) => ({
      ...state,
      contentSuccess,
    }),
    getContentError: (state, contentErr) => ({
      ...state,
      contentErr,
    }),
    subscribeUserSuccess: (state, subscribeSuccess) => ({
      ...state,
      subscribeSuccess,
    }),
    subscribeUserError: (state, subscribeErr) => ({
      ...state,
      subscribeErr,
    }),
    // getCrewMembersSuccess: (state, crewMembers) => ({
    //   ...state,
    //   crewMembers,
    // }),
    // getCrewMembersError: (state, usersErr) => ({
    //   ...state,
    //   usersErr,
    // }),
  }, // end reducers

  effects: (dispatch) => ({
    async getUser(id) {
      try {
        const res = await API.getUserProfile(id);
        this.getUserSuccess({ user: res.data.data });
        return res.data.data;
      } catch (err) {
        this.getUserError(err);
      }
    },
    // TODO: uncomment when crewMembers are created
    // async getCrewMembers() {
    //   try {
    //     const res = await API.getCrewMembers();
    //     this.getCrewMembersSuccess(res.data);
    //   } catch (err) {
    //     this.getCrewMembersSuccess(err);
    //   }
    // },
    async getProfileEvents(id, rootState) {
      const { user_id } = rootState.user.user;
   
      try {
        const res = await API.getProfileEvents(id, user_id);
        this.getEventsSuccess({ events: res.data.data });
        return res.data.data;
      } catch (err) {
        this.getEventsError(err);
      }
    },
    async getProfileContent(id, rootState) {
     const { user_id } = rootState.user.user;
      try {
        const res = await API.getProfileContent(id, user_id);
        this.getContentSuccess({ content: res.data.data });
        return res.data.data;
      } catch (err) {
        this.getContentError(err);
      }
    },
    async subscribeUser({ data, id }, rootState) {
       const { user_id } = rootState.user.user;
      try {
        const res = await API.subscribeUser(data);
        this.subscribeUserSuccess("success");
        await this.getProfileContent(id, user_id);
        await this.getProfileEvents(id, user_id);
        dispatch.auth.openToast({ toastMessage: "Changes saved to account." });
      } catch (err) {
        this.subscribeUserFailure(err);
      }
    },
  }), // end effects
  selectors: (slice) => ({
    profileEvents() {
      return slice((select) => select.profile.events);
    },
    profileContent() {
      return slice((select) => select.profile.content);
    },
    user() {
      return slice((select) => select.profile.user);
    },
  }),
};

export default profile;
