// import logger from 'redux-logger';
import { init as rematchInit } from '@rematch/core';


import * as models from './models';


const middlewares = [];

// TODO: comment out to enable logger
// if (process.env.NODE_ENV === 'development') {
//   // middleware.push(logger);
// }

// TODO: may not need this
// eslint-disable-next-line no-underscore-dangle
const devtoolOptions = typeof window === 'object' ? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;

export const store = rematchInit({
  models,
  redux: {
    middlewares,
    devtoolOptions,
  },
});